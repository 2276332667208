import * as React from 'react'
import type { HeadFC } from 'gatsby'

// Components
import DefaultHelmet from '../components/atoms/DefaultHelmet'
import PageTemplate from '../components/templates/PageTemplate'
import HomeTemplate from '../components/templates/HomeTemplate'

const IndexPage = () => (
  <PageTemplate>
    <HomeTemplate />
  </PageTemplate>
)

export default IndexPage

export const Head: HeadFC = () => (
  <>
    <title>Home - Comic Colombiano</title>
    <meta
      name="description"
      content="¡Novelas gráficas, revistillas, webcómics y más! Conoce aquí el catálogo más grande de cómic colombiano y sus autores. ¡La inscripción es gratuita!"
    />
    <meta
      name="keywords"
      content="colombiano,comic,base,de,datos,comics,webcomics,webcomic,novela,grafica,arte,revistillas,catalogo,cómic"
    />
    <DefaultHelmet />
  </>
)
